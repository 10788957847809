/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/plugins/aaGenericPopup.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/plugins/aa-generic-popup.js
**/

/*
 * aaGenericPopup.js
 */
function aaGenericPopup(paramUrl, paramWindowName, paramSize, paramWidth, paramHeight) {
    // Declare variables
    this.height = 0;
    this.width = 0;
    this.url = "";
    this.windowObject = null;
    this.windowName = "";
    this.size = "MEDIUM";

    // Initialize values
    this.initValues(paramUrl, paramWindowName, paramSize, paramWidth, paramHeight);
}

aaGenericPopup.initAnchor = function (element, paramSize, paramWidth, paramHeight) {
    if (element) {
        var href = element.href;
        var id = element.id;
        element.onclick = function (evt) {
            if (evt) {
                evt.preventDefault();
            } else { //IE7 & IE8 workaround
                event.returnValue = false;
            }
            aaGenericPopup.open(
                href,
                "window_" + id.replace(/-/g, "_"), // ie8 doesn't support hyphens in window names
                paramSize, paramWidth, paramHeight
            );
            return false;
        };
        element.target = "_blank";
    }
};
aaGenericPopup.prototype.initValues = function (paramUrl, paramWindowName, paramSize, paramWidth, paramHeight) {
    if (paramUrl) {
        this.url = paramUrl;
    }
    if (paramWindowName) {
        this.windowName = paramWindowName;
    }
    if (paramSize) {
        this.size = paramSize;
    }
    this.adjustSize(paramSize, paramWidth, paramHeight);
};
aaGenericPopup.prototype.adjustSize = function (paramSize, paramWidth, paramHeight) {
    if (paramSize) {
        this.size = paramSize.toUpperCase();
        switch (this.size) {
        case "SMALL":
            this.width = 300;
            this.height = 300;
            break;
        case "LARGE":
            this.width = 850;
            this.height = 500;
            break;
        case "SCREEN":
            this.width = screen.availWidth - 50;
            this.height = screen.availHeight - 50;
            break;
        case "CUSTOM":
            try {
                this.width = parseInt(paramWidth);
                this.height = parseInt(paramHeight);
                break;
            } catch (e) {
                // execution will continue below in the default: section and assume the default size
            }
        default: // this is where "MEDIUM" will be resolved
            this.width = 675;
            this.height = 500;
        }
    }
};
aaGenericPopup.prototype.open = function (paramUrl, paramWindowName, paramSize, paramWidth, paramHeight) {
    this.initValues(paramUrl, paramWindowName, paramSize, paramWidth, paramHeight);

    // Centralize the window in the page
    var localLeft =
        Math.floor(screen.availWidth / 2) -
        Math.floor(this.width / 2);
    var localTop =
        Math.floor(screen.availHeight / 2) -
        Math.floor(this.height / 2);

    var windowFeatures = ((this.size == "SCREEN") ? "location=1, status=1, scrollbars=1, menubar=1, toolbar=1, resizable=1" : "location=0, status=0, scrollbars=1, menubar=0, toolbar=0, resizable=1");

    this.windowObject = window.open(this.url, this.windowName,
        windowFeatures + ",width=" + this.width + ",height=" + this.height + ",top=" + localTop + ",left=" + localLeft
    );

    // Ensure that the popup window is not hidden beneath the main window
    if (this.windowObject !== null && this.windowObject !== undefined) { //Some IE versions will return null for window object
        this.windowObject.focus();
        return this.windowObject;
    }
    return;
};

aaGenericPopup.open = function (url, windowName, paramSize, paramWidth, paramHeight) {
    var obj = new aaGenericPopup(url, windowName, paramSize, paramWidth, paramHeight);
    obj.open();
    return false;
};

// ----------------------------------------------------------------------------------------------------------------------------------
// Register with jQuery as a plugin
// ----------------------------------------------------------------------------------------------------------------------------------
jQuery.fn.aaGenericPopup = function (paramSize, paramWidth, paramHeight) {
    var i = 0;
    for (i; i < this.length; i++) {
        aaGenericPopup.initAnchor(this[i], paramSize, paramWidth, paramHeight);
    }
};